/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Layout from "../components/Layout";

function PrivacyPolicyPage() {
  return (
    <Layout>
      <section className="section section--gradient">
        <div className="container">
        <h1 className="title">Privacy Policy</h1>

<p>Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.</p>
<br/>

<p>
  Questo documento può essere stampato utilizzando il comando di stampa presente nelle
  impostazioni di qualsiasi browser.
</p>
<br/>

<h2>Riassunto della policy</h2>

<h2 id="purposes_data">
  Dati personali raccolti per le seguenti finalità ed utilizzando i seguenti servizi:
</h2>

<ul>
  <li>
    Contattare l{"'"}Utente
    <ul>
      <li>Mailing list o newsletter Dati Personali: email</li>
<br/>
    </ul>
  </li>
<br/>
  <li>
    Gestione dei tag
    <ul>
      <li>Google Tag Manager Dati Personali: Cookie; Dati di utilizzo</li>
<br/>
    </ul>
  </li>
<br/>
  <li>
    Statistica
    <ul>
      <li>
        Google Analytics, Google Analytics con IP anonimizzato, Statistiche raccolte in modo
        diretto
      </li>
<br/>
      <li>Funnels Dati Personali: Cookie; Dati di utilizzo</li>
<br/>
    </ul>
    <ul>
      <li>
        Rapporti sui dati demografici e sugli interessi di Google Analytics Dati Personali:
        Cookie; identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID
        o identificatore IDFA, per esempio)
      </li>
<br/>
      <li>
        Funzionalità di generazione dei rapporti sulla pubblicità in Google Analytics Dati
        Personali: Cookie; identificatori univoci di dispositivi per la pubblicità (Google
        Advertiser ID o identificatore IDFA, per esempio); varie tipologie di Dati secondo
        quanto specificato dalla privacy policy del servizio
      </li>
<br/>
    </ul>
  </li>
<br/>
  <li>
    Visualizzazione di contenuti da piattaforme esterne
    <ul>
      <li>
        Google Fonts Dati Personali: Dati di utilizzo; varie tipologie di Dati secondo quanto
        specificato dalla privacy policy del servizio
      </li>
<br/>
      <li>Font Awesome Dati Personali: Dati di utilizzo</li>
<br/>
      <li>Google Site Search Dati Personali: Cookie; Dati di utilizzo</li>
<br/>
    </ul>
  </li>
<br/>
</ul>

<h2 id="further_data">Ulteriori informazioni sui Dati Personali</h2>

<ul>
  <li>
    Analisi dei Dati dell’Utente e previsioni (“profilazione”) Il Titolare potrebbe trattare i
    dati d’utilizzo raccolti attraverso questa Applicazione per creare o aggiornare profili di
    utenza. Questo tipo di trattamento consente al Titolare di valutare scelte, preferenze e
    comportamento dell’Utente per gli scopi specificati nelle rispettive sezioni di questo
    documento. I profili d’utenza possono essere creati anche grazie a strumenti
    automatizzati, come algoritmi, che possono anche essere offerti da terze parti. Per
    ottenere ulteriori informazioni sull’attività di profilazione l’Utente può fare
    riferimento alle rispettive sezioni di questo documento. L’Utente ha in ogni momento
    diritto ad opporsi a tale attività di profilazione. Per scoprire di più sui diritti
    dell’Utente e su come esercitarli, l’Utente può fare riferimento alla sezione di questo
    documento relativa ai diritti degli Utenti.
  </li>
<br/>
</ul>

<h2 id="contact_information">Informazioni di contatto</h2>

<ul>
  <li>Titolare del Trattamento dei Dati Esperti della grandine snc, Via Bizzozzero, 27 Torino P.IVA 612970017</li>
<br/>
</ul>

<h2>Policy completa</h2>

<h2 id="owner_of_the_data">Titolare del Trattamento dei Dati</h2>

<p>Esperti della grandine snc</p>
<br/>

<h2 id="types_of_data">Tipologie di Dati raccolti</h2>

<p>
  Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze
  parti, ci sono: Cookie; Dati comunicati durante l{"'"}utilizzo del servizio;
  varie tipologie di Dati.
</p>
<br/>

<p>
  Dettagli completi su ciascuna tipologia di dati raccolti sono forniti nelle sezioni dedicate
  di questa privacy policy o mediante specifici testi informativi visualizzati prima della
  raccolta dei dati stessi. I Dati Personali possono essere liberamente forniti dall{"'"}
  Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l{"'"}uso di questa
  Applicazione. Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione
  sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa
  Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati
  come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che
  ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività. Gli
  Utenti che dovessero avere dubbi su quali Dati siano obbligatori, sono incoraggiati a
  contattare il Titolare. L’eventuale utilizzo di Cookie - o di altri strumenti di
  tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati
  da questa Applicazione, ove non diversamente precisato, ha la finalità di fornire il
  Servizio richiesto dall{"'"}Utente, oltre alle ulteriori finalità descritte nel presente
  documento e nella Cookie Policy, se disponibile.
</p>
<br/>

<p>
  L{"'"}Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o
  condivisi mediante questa Applicazione e garantisce di avere il diritto di comunicarli o
  diffonderli, liberando il Titolare da qualsiasi responsabilità verso terzi.
</p>
<br/>

<h2 id="place_of_processing">Modalità e luogo del trattamento dei Dati raccolti</h2>

<h3>Modalità di trattamento</h3>

<p>
  Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la
  divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali. Il
  trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità
  organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al
  Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti
  nell’organizzazione di questa Applicazione (personale amministrativo, commerciale,
  marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di
  servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di
  comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del
  Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del
  Trattamento.
</p>
<br/>

<h3>Base giuridica del trattamento</h3>

<p>
  Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti
  condizioni:
</p>
<br/>

<ul>
  <li>
    l’Utente ha prestato il consenso per una o più finalità specifiche; Nota: in alcuni
    ordinamenti il Titolare può essere autorizzato a trattare Dati Personali senza che debba
    sussistere il consenso dell’Utente o un’altra delle basi giuridiche specificate di
    seguito, fino a quando l’Utente non si opponga (“opt-out”) a tale trattamento. Ciò non è
    tuttavia applicabile qualora il trattamento di Dati Personali sia regolato dalla
    legislazione europea in materia di protezione dei Dati Personali;
  </li>
<br/>
  <li>
    il trattamento è necessario all{"'"}esecuzione di un contratto con l’Utente e/o all{"'"}
    esecuzione di misure precontrattuali;
  </li>
<br/>
  <li>
    il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il
    Titolare;
  </li>
<br/>
  <li>
    il trattamento è necessario per l{"'"}esecuzione di un compito di interesse pubblico o per
    l{"'"}esercizio di pubblici poteri di cui è investito il Titolare;
  </li>
<br/>
  <li>
    il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di
    terzi.
  </li>
<br/>
</ul>

<p>
  È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di
  ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla
  legge, previsto da un contratto o necessario per concludere un contratto.
</p>
<br/>

<h3>Luogo</h3>

<p>
  I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le
  parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il
  Titolare. I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da
  quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del
  trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento
  dei Dati Personali.
</p>
<br/>

<p>
  L’Utente ha diritto a ottenere informazioni in merito alla base giuridica del trasferimento
  di Dati al di fuori dell’Unione Europea o ad un’organizzazione internazionale di diritto
  internazionale pubblico o costituita da due o più paesi, come ad esempio l’ONU, nonché in
  merito alle misure di sicurezza adottate dal Titolare per proteggere i Dati. L’Utente può
  verificare se abbia luogo uno dei trasferimenti appena descritti esaminando la sezione di
  questo documento relativa ai dettagli sul trattamento di Dati Personali o chiedere
  informazioni al Titolare contattandolo agli estremi riportati in apertura.
</p>
<br/>

<h3>Periodo di conservazione</h3>

<p>
  I Dati sono trattati e conservati per il tempo richiesto dalle finalità per le quali sono
  stati raccolti.
</p>
<br/>

<p>Pertanto:</p>
<br/>

<ul>
  <li>
    I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il
    Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale
    contratto.
  </li>
<br/>
  <li>
    I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare
    saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere
    ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle
    relative sezioni di questo documento o contattando il Titolare.
  </li>
<br/>
</ul>

<p>
  Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati
  Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare
  potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo in
  ottemperanza ad un obbligo di legge o per ordine di un’autorità. Al termine del periodo di
  conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il
  diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati
  non potranno più essere esercitati.
</p>
<br/>

<h2 id="use_collected_data">Finalità del Trattamento dei Dati raccolti</h2>

<p>
  I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio,
  adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i
  propri diritti ed interessi (o quelli di Utenti o di terze parti), individuare eventuali
  attività dolose o fraudolente, nonché per le seguenti finalità: Statistica, Contattare l
  {"'"}Utente, Protezione dallo SPAM, Gestione dei tag, Gestione contatti e invio di messaggi,
  Visualizzazione di contenuti da piattaforme esterne, Interazione con piattaforme di raccolta
  dati e altre terze parti, Servizi di piattaforma e hosting e Gestione della raccolta dati e
  dei sondaggi online.
</p>
<br/>

<p>
  Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali
  trattati per ciascuna finalità, l’Utente può fare riferimento alla sezione “Dettagli sul
  trattamento dei Dati Personali”.
</p>
<br/>

<h2 id="data_processing_detailed_info">Dettagli sul trattamento dei Dati Personali</h2>

<p>
  I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:
</p>
<br/>

<ul>
  <li>
    Gestione dei tag Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o
    script utilizzati su questa Applicazione. L{"'"}uso di tali servizi comporta il fluire dei
    Dati dell{"'"}Utente attraverso gli stessi e, se del caso, la loro ritenzione. Google Tag
    Manager (Google Ireland Limited) Google Tag Manager è un servizio di gestione dei tag
    fornito da Google Ireland Limited. Dati Personali trattati: Cookie; Dati di utilizzo.
    Luogo del trattamento: Irlanda –&nbsp;
    <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
      Privacy Policy
    </a>
  </li>
<br/>
</ul>

<ul>
  <li>
    Servizi di piattaforma e hosting Questi servizi hanno lo scopo di ospitare e far
    funzionare componenti chiave di questa Applicazione, rendendo possibile l’erogazione di
    questa Applicazione da un’unica piattaforma. Queste piattaforme forniscono al Titolare un
    {"'"}ampia gamma di strumenti quali, ad esempio, strumenti analitici, per la gestione
    della registrazione degli utenti, per la gestione dei commenti e del database, per il
    commercio elettronico, per l’elaborazione dei pagamenti etc. L’uso di tali strumenti
    comporta la raccolta e il trattamento di Dati Personali. Alcuni di questi servizi
    funzionano attraverso server dislocati geograficamente in luoghi differenti, rendendo
    difficile la determinazione del luogo esatto in cui vengono conservati i Dati
    Personali.WordPress.com (Automattic Inc.) WordPress.com è una piattaforma fornita da
    Automattic Inc. che consente al Titolare di sviluppare, far funzionare ed ospitare questa
    Applicazione. Dati Personali trattati: varie tipologie di Dati secondo quanto specificato
    dalla privacy policy del servizio. Luogo del trattamento: Stati Uniti –&nbsp;
    <a rel="noreferrer noopener" href="https://automattic.com/privacy/" target="_blank">
      Privacy Policy
    </a>
    .
  </li>
<br/>
  <li>
    Statistica I servizi contenuti nella presente sezione permettono al Titolare del
    Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del
    comportamento dell’Utente. Google Analytics (Google Ireland Limited)Google Analytics è un
    servizio di analisi web fornito da Google Ireland Limited (“Google”). Google utilizza i
    Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa
    Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.
    Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli
    annunci del proprio network pubblicitario. Dati Personali trattati: Cookie; Dati di
    utilizzo. Luogo del trattamento: Irlanda –&nbsp;
    <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
      Privacy Policy
    </a>
    &nbsp;–&nbsp;
    <a
      rel="noreferrer noopener"
      href="https://tools.google.com/dlpage/gaoptout?hl=en"
      target="_blank"
    >
      Opt Out
    </a>
    . Rapporti sui dati demografici e sugli interessi di Google Analytics (Google Ireland
    Limited) Rapporti sui dati demografici e sugli interessi di Google Analytics è una
    funzionalità di generazione dei rapporti sulla pubblicità che rende disponibili i Dati
    demografici e di interesse all{"'"}interno di Google Analytics per questa Applicazione
    (per Dati demografici si intendono i Dati su età e sesso).&nbsp;Gli Utenti possono
    scegliere di non utilizzare i cookie di Google visitando le impostazioni annunci
    (https://adssettings.google.com/authenticated) di Google. Dati Personali trattati: Cookie;
    identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o
    identificatore IDFA, per esempio).Luogo del trattamento: Irlanda –&nbsp;
    <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
      Privacy Policy
    </a>
    &nbsp;–&nbsp;
    <a
      rel="noreferrer noopener"
      href="https://tools.google.com/dlpage/gaoptout"
      target="_blank"
    >
      Opt Out
    </a>
    . Funzionalità di generazione dei rapporti sulla pubblicità in Google Analytics (Google
    Ireland Limited) Google Analytics su questa Applicazione ha attivato le funzionalità di
    generazione dei rapporti sulla pubblicità, che raccolgono informazioni aggiuntive dagli ID
    pubblicitari del dispositivo (attività dell{"'"}applicazione). Questo permette al
    Proprietario di analizzare specifici Dati relativi ai comportamenti e agli interessi degli
    Utenti (Dati sul traffico e Dati di interazione con gli annunci da parte degli Utenti) e,
    se abilitati, Dati demografici (informazioni su età e sesso).&nbsp;Gli Utenti possono
    scegliere di non utilizzare i cookie di Google visitando le [Impostazioni annunci]
    (https://adssettings.google.com/authenticated) di Google. Dati Personali trattati: Cookie;
    identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o
    identificatore IDFA, per esempio); varie tipologie di Dati secondo quanto specificato
    dalla privacy policy del servizio. Luogo del trattamento: Irlanda –&nbsp;
    <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
      Privacy Policy
    </a>
    &nbsp;–&nbsp;
    <a
      rel="noreferrer noopener"
      href="https://tools.google.com/dlpage/gaoptout"
      target="_blank"
    >
      Opt Out
    </a>
    . Google Analytics con IP anonimizzato (Google Ireland Limited)Google Analytics è un
    servizio di analisi web fornito da Google Ireland Limited (“Google”). Google utilizza i
    Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa
    Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.
    Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli
    annunci del proprio network pubblicitario. Questa integrazione di Google Analytics rende
    anonimo il tuo indirizzo IP. L{"'"}anonimizzazione funziona abbreviando entro i confini
    degli stati membri dell{"'"}Unione Europea o in altri Paesi aderenti all{"'"}accordo sullo
    Spazio Economico Europeo l{"'"}indirizzo IP degli Utenti. Solo in casi eccezionali, l{"'"}
    indirizzo IP sarà inviato ai server di Google ed abbreviato all{"'"}interno degli Stati
    Uniti.Dati Personali trattati: Cookie; Dati di utilizzo.Luogo del trattamento: Irlanda
    –&nbsp;
    <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
      Privacy Policy
    </a>
    &nbsp;–&nbsp;
    <a
      rel="noreferrer noopener"
      href="https://tools.google.com/dlpage/gaoptout?hl=en"
      target="_blank"
    >
      Opt Out
    </a>
    . Statistiche raccolte in modo diretto (questa Applicazione) Questa Applicazione utilizza
    un sistema di statistiche interno, che non coinvolge terze parti. Dati Personali trattati:
    Cookie; Dati di utilizzo.
  </li>
<br/>
  <li>
    Visualizzazione di contenuti da piattaforme esterne Questo tipo di servizi permette di
    visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa
    Applicazione e di interagire con essi. Questo tipo di servizio potrebbe comunque
    raccogliere dati sul traffico web relativo alle pagine dove il servizio è installato,
    anche quando gli utenti non lo utilizzano. Font Awesome (Fonticons, Inc. ) Font Awesome è
    un servizio di visualizzazione di stili di carattere gestito da Fonticons, Inc. che
    permette a questa Applicazione di integrare tali contenuti all’interno delle proprie
    pagine. Dati Personali trattati: Dati di utilizzo. Luogo del trattamento: Stati Uniti
    –&nbsp;
    <a rel="noreferrer noopener" href="https://fontawesome.com/privacy" target="_blank">
      Privacy Policy
    </a>
    . Google Fonts (Google Ireland Limited) Google Fonts è un servizio di visualizzazione di
    stili di carattere gestito da Google Ireland Limited che permette a questa Applicazione di
    integrare tali contenuti all’interno delle proprie pagine. Dati Personali trattati: Dati
    di utilizzo; varie tipologie di Dati secondo quanto specificato dalla privacy policy del
    servizio. Luogo del trattamento: Irlanda –&nbsp;
    <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
      Privacy Policy
    </a>
    . Google Site Search (Google Ireland Limited)Google Site Search è un servizio di
    incorporamento di motori di ricerca gestito da Google Ireland Limited che permette a
    questa Applicazione di integrare tali contenuti all{"'"}interno delle proprie pagine.Dati
    Personali trattati: Cookie; Dati di utilizzo. Luogo del trattamento: Irlanda –&nbsp;
    <a rel="noreferrer noopener" href="https://policies.google.com/privacy" target="_blank">
      Privacy Policy
    </a>
    .
  </li>
<br/>
</ul>

<h2 id="further_data">Ulteriori informazioni sui Dati Personali</h2>

<ul>
  <li>
    Analisi dei Dati dell’Utente e previsioni (“profilazione”)Il Titolare potrebbe trattare i
    dati d’utilizzo raccolti attraverso questa Applicazione per creare o aggiornare profili di
    utenza. Questo tipo di trattamento consente al Titolare di valutare scelte, preferenze e
    comportamento dell’Utente per gli scopi specificati nelle rispettive sezioni di questo
    documento. I profili d’utenza possono essere creati anche grazie a strumenti
    automatizzati, come algoritmi, che possono anche essere offerti da terze parti. Per
    ottenere ulteriori informazioni sull’attività di profilazione l’Utente può fare
    riferimento alle rispettive sezioni di questo documento. L’Utente ha in ogni momento
    diritto ad opporsi a tale attività di profilazione. Per scoprire di più sui diritti
    dell’Utente e su come esercitarli, l’Utente può fare riferimento alla sezione di questo
    documento relativa ai diritti degli Utenti.
  </li>
<br/>
</ul>

<h2 id="rights_subjects">Diritti dell’Utente</h2>

<p>
  Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal
  Titolare.
</p>
<br/>

<p>In particolare, l’Utente ha il diritto di:</p>
<br/>

<ul>
  <li>
    <strong>revocare il consenso in ogni momento.</strong>&nbsp;L’Utente può revocare il
    consenso al trattamento dei propri Dati Personali precedentemente espresso.
  </li>
<br/>
  <li>
    <strong>opporsi al trattamento dei propri Dati.</strong>&nbsp;L’Utente può opporsi al
    trattamento dei propri Dati quando esso avviene su una base giuridica diversa dal
    consenso. Ulteriori dettagli sul diritto di opposizione sono indicati nella sezione
    sottostante.
  </li>
<br/>
  <li>
    <strong>accedere ai propri Dati.</strong>&nbsp;L’Utente ha diritto ad ottenere
    informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a
    ricevere una copia dei Dati trattati.
  </li>
<br/>
  <li>
    <strong>verificare e chiedere la rettificazione.</strong>&nbsp;L’Utente può verificare la
    correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.
  </li>
<br/>
  <li>
    <strong>ottenere la limitazione del trattamento.</strong>&nbsp;Quando ricorrono
    determinate condizioni, l’Utente può richiedere la limitazione del trattamento dei propri
    Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro
    conservazione.
  </li>
<br/>
  <li>
    <strong>ottenere la cancellazione o rimozione dei propri Dati Personali.</strong>
    &nbsp;Quando ricorrono determinate condizioni, l’Utente può richiedere la cancellazione
    dei propri Dati da parte del Titolare.
  </li>
<br/>
  <li>
    <strong>ricevere i propri Dati o farli trasferire ad altro titolare.</strong>
    &nbsp;L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune
    e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il
    trasferimento senza ostacoli ad un altro titolare. Questa disposizione è applicabile
    quando i Dati sono trattati con strumenti automatizzati ed il trattamento è basato sul
    consenso dell’Utente, su un contratto di cui l’Utente è parte o su misure contrattuali ad
    esso connesse.
  </li>
<br/>
  <li>
    <strong>proporre reclamo.</strong>&nbsp;L’Utente può proporre un reclamo all’autorità di
    controllo della protezione dei dati personali competente o agire in sede giudiziale.
  </li>
<br/>
</ul>

<h3>Dettagli sul diritto di opposizione</h3>

<p>
  Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici
  poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del
  Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro
  situazione particolare.
</p>
<br/>

<p>
  Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing
  diretto, possono opporsi al trattamento senza fornire alcuna motivazione. Per scoprire se il
  Titolare tratti dati con finalità di marketing diretto gli Utenti possono fare riferimento
  alle rispettive sezioni di questo documento.
</p>
<br/>

<h3>Come esercitare i diritti</h3>

<p>
  Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una richiesta agli
  estremi di contatto del Titolare indicati in questo documento. Le richieste sono depositate
  a titolo gratuito e evase dal Titolare nel più breve tempo possibile, in ogni caso entro un
  mese.
</p>
<br/>

<h2 id="cookie_policy">Cookie Policy</h2>

<p>
  Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per saperne di più, l’Utente
  può consultare la&nbsp; Cookie Policy.
</p>
<br/>

<h2 id="further_data_processing_info">Ulteriori informazioni sul trattamento</h2>

<h3>Difesa in giudizio</h3>

<p>
  I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o
  nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell{"'"}
  utilizzo di questa Applicazione o dei Servizi connessi da parte dell’Utente. L’Utente
  dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati
  per ordine delle autorità pubbliche.
</p>
<br/>

<h3>Informative specifiche</h3>

<p>
  Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy,
  questa Applicazione potrebbe fornire all{"'"}Utente delle informative aggiuntive e
  contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati
  Personali.
</p>
<br/>

<h3>Log di sistema e manutenzione</h3>

<p>
  Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli
  eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file
  che registrano le interazioni e che possono contenere anche Dati Personali, quali
  l’indirizzo IP Utente.
</p>
<br/>

<h3>Informazioni non contenute in questa policy</h3>

<p>
  Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere
  richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di
  contatto.
</p>
<br/>

<h3>Risposta alle richieste “Do Not Track”</h3>

<p>
  Questa Applicazione non supporta le richieste “Do Not Track”. Per scoprire se gli eventuali
  servizi di terze parti utilizzati le supportino, l{"'"}Utente è invitato a consultare le
  rispettive privacy policy.
</p>
<br/>

<h3>Modifiche a questa privacy policy</h3>

<p>
  Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente
  privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se
  possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile,
  inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in
  possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento
  alla data di ultima modifica indicata in fondo. Qualora le modifiche interessino trattamenti
  la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il
  consenso dell’Utente, se necessario.
</p>
<br/>

<h3 id="definitions_and_legal_references">Definizioni e riferimenti legali</h3>

<h4>Dati Personali (o Dati)</h4>

<p>
  Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche
  in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione
  personale, renda identificata o identificabile una persona fisica.
</p>
<br/>

<h4>Dati di Utilizzo</h4>

<p>
  Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da
  applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i
  nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione,
  gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il
  metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in
  risposta, il codice numerico indicante lo stato della risposta dal server (buon fine,
  errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema
  operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad
  esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario
  seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle
  pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico
  dell’Utente.
</p>
<br/>

<h4>Utente</h4>

<p>
  L{"'"}individuo che utilizza questa Applicazione che, salvo ove diversamente specificato,
  coincide con l{"'"}Interessato.
</p>
<br/>

<h4>Interessato</h4>

<p>La persona fisica cui si riferiscono i Dati Personali.</p>
<br/>

<h4>Responsabile del Trattamento (o Responsabile)</h4>

<p>
  La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta
  dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
</p>
<br/>

<h4>Titolare del Trattamento (o Titolare)</h4>

<p>
  La persona fisica o giuridica, l{"'"}autorità pubblica, il servizio o altro organismo che,
  singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati
  personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al
  funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo
  quanto diversamente specificato, è il titolare di questa Applicazione.
</p>
<br/>

<h4>Questa Applicazione</h4>

<p>
  Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali
  degli Utenti.
</p>
<br/>

<h4>Servizio</h4>

<p>
  Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se
  presenti) su questo sito/applicazione.
</p>
<br/>

<h4>Unione Europea (o UE)</h4>

<p>
  Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo
  documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello
  Spazio Economico Europeo.
</p>
<br/>

<h4>Cookie</h4>

<p>
  I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati
  conservate all{"'"}interno del browser dell{"'"}Utente.
</p>
<br/>

<h4>Strumento di Tracciamento</h4>

<p>
  Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi
  univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare
  gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.
</p>
<br/>

<hr />

<h4>Riferimenti legali</h4>

<p>
  La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi,
  inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.
</p>
<br/>

<p>
  Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa
  Applicazione.
</p>
<br/>

<p>Ultima modifica: 1 Marzo 2022</p>
<br/>
        </div>
      </section>
    </Layout>
  );
}

export default PrivacyPolicyPage;